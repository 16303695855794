import './ScreenBtn.css'
import {Tooltip} from "@mui/material";
import screenshot from '../../../static/screenshot.png'
import html2canvas from 'html2canvas'; 
import useInfoPopup from "../../../hooks/useInfoPopup";
import {useDispatch} from "react-redux";

const ScreenBtn = () => {
    const {setPopupStatus} = useInfoPopup()
    const dispatch = useDispatch()

    const handleScreenshot = async () => {
        console.log('handleScreenshot')
        try {
            const canvas = await html2canvas(document.body, {
                useCORS: true,
                windowWidth: window.innerWidth,
                windowHeight: window.innerHeight
            });
          
            canvas.toBlob(async (blob) => {
            try {
                console.log('save blob in copyboard')
                console.log(blob)
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'image/png': blob
                    })
                ]);
                setPopupStatus(true, 'Image copied to clipboard', true)
            } catch (error) {
                setPopupStatus(false, 'Error on coping image to clipboard', true)
                console.error('Ошибка сохранения в буфер обмена:', error);
            }
            });
        } catch (error) {
            console.error('Ошибка создания скриншота:', error);
        }
    };

    return (
        <Tooltip arrow title={"Press this button to screen display"}>
            <div onClick={handleScreenshot} className="snapshot-btn__container">
                <img src={screenshot} alt="screen"/>
            </div>
        </Tooltip>
    )
}

export default ScreenBtn;